import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FRAuth, Config, FRStep, AttributeInputCallback, ChoiceCallback, DeviceProfileCallback, FRDevice } from '@forgerock/javascript-sdk';
import { CallbackType, NameCallback, PasswordCallback } from '@forgerock/javascript-sdk';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/services/main.service';
import { DataShare } from 'src/app/services/datashare';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NotificationService } from 'src/app/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-bae-login',
  templateUrl: './bae-login.component.html',
  styleUrls: ['./bae-login.component.css']
})
export class BaeLoginComponent implements OnInit {
  currentStep: any;
  showPassword = false;
  loginForm!: FormGroup;
  choiceCB: any;
  codeType: any
  submitted = false;
  user = '';
  jsonData: any;
  corpCode: any = ''
  showTOU: boolean = false
  email: any;
  //determines what step its performing ex:verify, otp
  stepIs: string = '';
  phone: any;
  devicePrint: any = {};
  singleVal: string = ''
  version: any = ''
  deviceDetails: any;
  cStep: number;
  ismulti: any;
  tree: any;
  isChecked: boolean = false;
  emailIndexVal : number ;
 phoneIndexVal: number ;
  saveDevice: any=0
  mfa: boolean = false;
  otpCode: any;
  touaccepted: boolean = false;
  loadingBar = false;
  resendEmailLink:string='';
  username:string='';
  sendCodeform = this.formBuilder.group({
    sendCodeType: new FormControl('email', Validators.required)
  });
  amUrl: any = window.location.href.split(".com").length == 1 ? 'il' : window.location.href.split(".com")[0].slice(-2)
  previousCallback: any;
  //when clicked on a link for TOU remaining page blocks and goes to the top
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private mainService: MainService,
    private dataShare: DataShare,
    private notifyService: NotificationService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    Config.set({
      serverConfig: { baseUrl: this.amUrl === 'il' ?  environment.AM_URL_IL
          : this.amUrl === 'tx' ? environment.AM_URL_TX
            : this.amUrl === 'mt' ? environment.AM_URL_MT
              : this.amUrl === 'ok' ? environment.AM_URL_OK
                : this.amUrl === 'nm' ? environment.AM_URL_NM : environment.AM_URL_IL,
       timeout: 10000 },
      tree: this.tree ? this.tree : environment.DEFAULT_TREE,
      realmPath: environment.REALM_PATH,
    });

  }
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";

  ngOnInit() {
    console.log('checking environment',environment.NODE_ENV);
    this.resendEmailLink=environment.BAE_RESEND_EMAIL;
    this.getScrennData()
    //this.collectGeolocationInfo()
    let urlArray = window.location.href.split(".com");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    this.mainService.getBaeTermsData(this.corpCode).subscribe((res: any) => {
      var doc: any = new DOMParser().parseFromString(res[0].labelDescription, "text/html");
      this.version = res[0].versionId
      this.jsonData = this.sanitizer.bypassSecurityTrustHtml(doc.firstChild.innerHTML);
    });

    this.nextStep(this.dataShare.step);
    this.loginForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.minLength(4),
      Validators.maxLength(40)]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]]
    });

    this.blockUI.stop();
  }

  // convenience getter for easy access to form fields
  get userLoginForm(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  removeSpaces()
  {
	this.loginForm.value.userId = this.loginForm.value.userId.trim();
	this.loginForm.value.password= this.loginForm.value.password.trim();
  }

  onSubmit() {
	this.removeSpaces();
    this.submitted = true;
    // stop here if form is invalid
    this.mainService.getIPAddress(this.loginForm.value.userId,this.corpCode ).subscribe( (data:any) =>{
        if(data!='undefined' && data === 'Allowed'){
          console.log('IP Address Allowed')
          if (this.loginForm.invalid) {
            return;
          } else {
            this.setCallBack()
          }
          this.submitted = false;
        }else{
          console.log('IP Address Not Allowed')
         let remoteIP;
	     remoteIP = this.mainService.getRemoteIPAddress(this.loginForm.value.userId).subscribe( ( data:any) =>{
		remoteIP =data;
		alert('A problem has occurred. Please contact your Delegated Administrator for more information and give them the following error code: Unauthorized IP Access. Incoming IP is: ' + remoteIP);
	});
	this.router.navigate(['/employer/login']);
        }
    });
   
  }

  backtoStep2() {
    this.stepIs = 'verify'
  }

  onOtpChange(code: any) {
    this.otpCode = code;
  }

  handleSelection() {
    this.codeType = this.sendCodeform.controls.sendCodeType.value
    this.choiceCallback(this.currentStep, this.codeType);
  }
  handleResend() {
    this.notifyService.showSuccess(`Code sent to ${this.codeType}`, "Success")
    this.choiceCallback(this.choiceCB, this.codeType).then(res => {

    });
  }

  async choiceCallback(step: FRStep, codeSelected: string) {
    this.choiceCB = step
    step.callbacks?.forEach((callback, index) => {


      if (callback.getType() === "ChoiceCallback") {

        if (callback.payload.output.length == 1 && callback.payload.output.values[0] === "Resend") {
          (callback as ChoiceCallback).setInputValue(0);
        }
        else if (callback.payload.input[0].name === 'IDToken1') {
          let optedType = codeSelected === 'phone' ? 1 : 0;


          (callback as ChoiceCallback).setInputValue(optedType);
          this.stepIs = 'otp'
        }
      }
    });

    this.currentStep = await FRAuth.next(step);
    return this.currentStep;
  }

  setCallBack() {
    console.log('Set callback')
    let urlArray = window.location.href.split(".com");
    let corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    let i = 0

    if (this.currentStep?.type === "LoginSuccess") {
      this.redirectToEmployer()
      return false
    }
    else {
      this.currentStep.callbacks.forEach(callback => {
        i++
        console.log('Callback in SetCallback(): ', callback);
        switch (callback.getType()) {
          case CallbackType.NameCallback: {
            (callback as NameCallback).setName(`${corpCode.toUpperCase()}1.EMP.${this.loginForm.value.userId}`);
            this.user = this.loginForm.value.userId
             if (callback.payload.output[0].value === 'Enter one time password') {
              if (this.otpCode == null || this.otpCode == "") {
                this.otpCode = "000000000";
              }
              (callback as NameCallback).setInputValue(this.otpCode);
            }
            break;
          }

          case CallbackType.PasswordCallback: {
            if (callback.payload.output[0].value === 'One Time Password') {
              if (this.otpCode == null || this.otpCode == "") {
                this.otpCode = "000000000";
              }
              (callback as PasswordCallback).setPassword(this.otpCode);
              console.log('OTP Code in SetCallback(): ', this.otpCode);
            } else {
              (callback as PasswordCallback).setPassword(this.loginForm.value.password);
            }

            break;
          }

          case CallbackType.StringAttributeInputCallback: {
            if (callback.payload.input[0].name === "IDToken1") {
              (callback as AttributeInputCallback<any>).setInputValue(environment.BAE_APP, 0);
              console.log('Callback inside string attribute input callback for app: SetCallback(): ', callback)
            } else {
              (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
              console.log('Callback inside string attribute input callback for corpcode: SetCallback(): ', callback)

            }
            break;

          }

          case CallbackType.ChoiceCallback: {
            console.log('ChoiceCallback setCallback() start')
            if(callback.payload.output[0].value==='The one-time verification code is incorrect. Do you want to resend?'){
              console.log('setCallback ChoiceCallback: ',callback.payload.output[0].value);
              this.notifyService.showError('The one-time verification code you entered is incorrect. Enter the correct code or click the \'resend it\' link to receive another code', 'Unsuccessful')
            }else{
              if (callback.payload.input[0].name === 'IDToken1') {
                this.cStep = this.currentStep
                this.showTOU = true
                console.log('ChoiceCallback setCallback(): ShowToU (true): ', this.showTOU);
  
              } else {
                this.showTOU = false;
                console.log('ChoiceCallback setCallback(): ShowToU (false): ', this.showTOU);
  
              }
            }
           // this.onSubmit();
            // this.gotoNext(this.currentStep)
            break;
          }

          default: {
            console.error('Unrecognized callback type.');
            break;
          }
        }


      })
      console.log('SetCallback() end: Starting next step: ', this.currentStep);
      this.nextStep(this.currentStep) 
    }
   

  }

  async nextStep(step: FRStep) {
    if(this.showTOU === true || this.touaccepted){
      console.log('Show TOU is true in NextStep()');
      return;
    }else{
    console.log('Step paramater in nextStep(): ', step);
    this.previousCallback = step;
    this.currentStep = await FRAuth.next(step);
    console.log('Current Step in nextStep(): ', this.currentStep);

    this.currentStep.callbacks?.forEach((callback, index) => {
      console.log('Callback in nextStep(): ', callback);


      if ((callback.getType() === 'StringAttributeInputCallback') && index == 0) {
        console.log('Calling setCallback() in nextStep(): ');
        this.setCallBack()
        }
      if (index == 0 && callback.getType() === "PasswordCallback") {
        if (callback.payload.output[0].value === 'Password') {
          this.dataShare.step = this.currentStep;
          console.log('Data share step in nextStep()', this.dataShare.step);
          this.router.navigate(['/employer/update-password']);
        }

      }
      if (callback.getType() === "TextOutputCallback") {
        this.loadingBar=false;
        let result = this.currentStep?.callbacks[0]?.payload.output[0]?.value
        result = JSON.parse(result)
      if(result?.policyRequirements[0]?.policyRequirement === "IS_NEW") {
        this.notifyService.showError("New password cannot be the same as your previous password", "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CHARS_NOT_ALLOWED") {
        this.notifyService.showError('Password cannot contain certain characters', "Invalid Password")
      } else if(result?.policyRequirements[0]?.policyRequirement=== "SEQ_CHARS_NOT_ALLOWED") {
        this.notifyService.showError('More than two sequential characters are not allowed', "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CONSECUTIVE_DIGITS_NOT_ALLOWED") {
        this.notifyService.showError('Consecutive digits Not Allowed', "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CANNOT_CONTAIN_OTHERS") {
        this.notifyService.showError('Password cannot contain your BCBS Plan State', "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "givenName") {
        this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "sn") {
        this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "hcscUserNameSuffix"){
        this.notifyService.showError("Password cannot contain user id", "Invalid Password")
      } else {
        this.notifyService.showError(result?.policyRequirements[0]?.policyRequirement, "Invalid Password")
      }
          this.dataShare.step = this.previousCallback
          console.log('Data share step in nextStep()', this.dataShare.step);
          this.router.navigate(['/employer/update-password']);

      }
      if (callback.getType() === 'DeviceProfileCallback') {
        (callback as DeviceProfileCallback).setInputValue(JSON.stringify(this.deviceDetails))
        console.log('Calling nextStep in DeviceProfileCallback: ');
        this.nextStep(this.currentStep)
      }

      if (callback.getType() === "ChoiceCallback") {
        this.loadingBar=false;
        if (callback.payload.output[0].value === 'The one-time verification code is incorrect. Do you want to resend?') {
          this.notifyService.showError('The one-time verification code you entered is incorrect. Enter the correct code or click the \'resend it\' link to receive another code', 'Unsuccessful')
        } else if (callback.payload.output[0].value === 'Do you want to add this device as saved device?') {
          (callback as ChoiceCallback).setInputValue(this.saveDevice);
          console.log('ChoiceCallback saveDevice',this.saveDevice);
          callback.payload.input[0].value=this.saveDevice;
          this.nextStep(this.currentStep)
        }
        // if (this.currentStep.payload.callbacks[0].output[0].value === 'Enter the OTP Method') {
        //   this.stepIs = 'verify'
        //   this.mfa = true;
        //   if (this.mfa) {
        //     callback.payload.input[0].value = 0;
        //   } else {
        //     callback.payload.input[0].value = 1;
        //   }
        // }
        //Added by U434324 
        if(this.currentStep?.payload?.callbacks[0]?.output[0].value === 'Choose the application ID' ){
          let appchoiceArr = this.currentStep?.payload?.callbacks[0]?.output[1].value
          console.log('appchoiceArr',appchoiceArr)
          callback.payload.input[0].value = 0; // emp appId need to compare from property file
          this.nextStep(this.currentStep);
        }
        if(this.currentStep?.payload?.callbacks[0]?.output[0].value === 'Enter the OTP Method' ){
          this.stepIs = 'verify'
          this.mfa = true;
          if (this.mfa) {
            callback.payload.input[0].value = 0;
          } else {
            callback.payload.input[0].value = 1;
          }
          if (this.currentStep?.payload?.callbacks[0]?.output[1].value.length > 1) {
            this.ismulti = true;
            let optionsArray = this.currentStep?.payload?.callbacks[0]?.output[1].value;
            optionsArray.forEach(ele => {
              let i = optionsArray.indexOf(ele);
              console.log('ele', ele);
              if (ele.includes("Email")) {
               this.emailIndexVal = i ;
                this.email = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[i]?.split(':')[1];
              
                console.log('array has Email', this.email, 'index', i);
              }
              if (ele.includes("Mobile")) {
                this.phoneIndexVal = i ;
                this.phone = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[i]?.split(':')[1];
             
                console.log('array has phone', this.phone, 'index', i);
              }
             
            })
          } else {
            this.ismulti = false;
            this.singleVal = this.currentStep?.payload?.callbacks[0]?.output[1].value[0];
          }
        }

       
        // if (this.currentStep?.payload?.callbacks[0]?.output[1].value.length === 1) {
        //   this.ismulti = false
        //   this.singleVal = this.currentStep?.payload?.callbacks[0]?.output[1].value[0]
        // } else {
        //   this.ismulti = true
        // }
        // this.email = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[0]?.split(':')[1]
        // this.phone = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[1]?.split(':')[1]
        if (callback.payload.output[0].value === 'ToU is not latest') {
          console.log('Calling setCallback() in Tou is not latest: NextStep()');
          this.setCallBack();
        }
      }


    })
    if (this.currentStep.type === "LoginSuccess") {
      this.setCallBack();
    }
    if(this.currentStep.type === "LoginFailure") {
        if(this.currentStep?.payload?.message === 'Login Failed - Email is not verified'){	
		   this.resendEmail();
        }else if (this.currentStep?.payload?.message === 'User Locked Out.'){
            this.notifyService.showError("User Locked Out. You may reset your password after 30 minutes by selecting Forgot Password or contact the Internet Help Desk for assistance at 1-888-706-0583.", "Login Fail");
        }else{
		 this.notifyService.showError(this.currentStep?.payload?.message, "Login Fail");
		}
      this.currentStep = this.previousCallback;
      // this.currentStep.callbacks[1].payload.input[0].value = '';
    }

  }
}

resendEmail(){
	this.username = this.corpCode+1+'.EMP.'+this.loginForm.value.userId;
	console.log( "username:" + this.username );
  this.mainService.resendBAEEmail(this.corpCode,this.username ).subscribe( (data:any) =>{   
  if(data!='undefined'&& data === "Success")
  {      
  	this.notifyService.showError('Email is not verified.<br/> We are sending you a new email to verify your email address.', "Login Fail");
  }
  else{
	this.notifyService.showError('Login Failed - Email is not verified.<br/> For assistance, call our Internet Help Desk at 1-888-706-0583', "Login Fail");
	}
});
}

  onReturnToLogin() {
    this.showTOU = false;
    this.stepIs='tourejected';
    this.loginForm.controls.userId.setValue('');
    this.loginForm.controls.password.setValue('');
    this.router.navigate(['/employer/login']);
  }
  onContinueNext() {
    if(this.isChecked){
      this.saveDevice=1;
    }else{
      this.saveDevice=0;
    }
    this.setCallBack();
  }

  handleDevice(type) {
    this.codeType = type
  }

  onTermsAccept() {
    this.loadingBar=true;
    this.dataShare.setTouAccepted(new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
    this.touaccepted = true;
    this.showTOU = false
    this.stepIs = 'touaccepted';
    this.gotoNext(this.currentStep)
  }

  redirectToEmployer() {
    if (this.corpCode === 'il') {
      this.loadingBar=false;
      this.cookieService.set('baembr', this.loginForm.value.userId.trim(), { domain: environment.DOMAIN_IL });
      window.location.href = environment.BAE_FORWARD_IL
    } else if (this.corpCode === 'tx') {
      this.loadingBar=false;
      this.cookieService.set('baembr', this.loginForm.value.userId.trim(), { domain: environment.DOMAIN_TX });
      window.location.href = environment.BAE_FORWARD_TX
    } else if (this.corpCode === 'mt') {
      this.loadingBar=false;
      this.cookieService.set('baembr', this.loginForm.value.userId.trim(), { domain: environment.DOMAIN_MT });
      window.location.href = environment.BAE_FORWARD_MT
    } else if (this.corpCode === 'ok') {
      this.loadingBar=false;
      this.cookieService.set('baembr', this.loginForm.value.userId.trim(), { domain: environment.DOMAIN_OK });
      window.location.href = environment.BAE_FORWARD_OK
    } else if (this.corpCode === 'nm') {
      this.loadingBar=false;
      this.cookieService.set('baembr', this.loginForm.value.userId.trim(), { domain: environment.DOMAIN_NM });
      window.location.href = environment.BAE_FORWARD_NM
    }
  }

  async gotoNext(step: FRStep) {
    console.log('goToNext() start:', step)
    this.currentStep = await FRAuth.next(step);
    if (this.currentStep.type == 'LoginSuccess') {
      this.redirectToEmployer()
      return;
    }
    else {
      let touAcceptedDate = this.dataShare.getTouAccepted();
      this.currentStep.callbacks.forEach((callback) => {
        const touString = `{\"termsOfService\":{\"hcscAppID\":\"APP00000430\",\"corpCode\":\"${this.corpCode.toUpperCase()}1\",\"acceptedDate\":\"${touAcceptedDate}\",\"version\":\"${this.version}\"}}`
        switch (callback.getType() as CallbackType) {
          case CallbackType.StringAttributeInputCallback: {
            console.log('Getting prompt in goToNExt(): ', (callback as AttributeInputCallback<any>).getPrompt())
            if ((callback as AttributeInputCallback<any>).getPrompt() != 'ToU') {
              if (callback.payload.input[0].name === "IDToken1") {
                (callback as AttributeInputCallback<any>).setInputValue(environment.BAE_APP, 0);
                console.log('GoToNext() input 1 app set: ', (callback as AttributeInputCallback<any>).setInputValue(environment.BAE_APP, 0) )
              } else {
                (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
                console.log('Setting corp code');
              }
            } else {
              console.log('goToNext TOuAccepted: ', this.touaccepted)
              if (this.touaccepted) {
                (callback as AttributeInputCallback<any>).setInputValue(touString);
              }else{
                this.router.navigate(['/employer/login']);
              }
            }
            break;
          }

          case CallbackType.ChoiceCallback: {
            if (callback.payload.input[0].name === 'IDToken1') {
              this.cStep = this.currentStep
              this.showTOU = true;
              
            } else {
              this.showTOU = false
            }
            break;
          }
          default: {
            console.error('Unrecognized callback type.');
            break;
          }
        }
      })
    }
    this.gotoNext(this.currentStep)
  }

  onCreateClick() {
    this.router.navigate(['/employer/terms-conditions']);
  }
  onClickForgotPwd() {
    this.router.navigate(['/employer/forgot-password']);
  }
  onClickForgotUser() {
    this.router.navigate(['/employer/forgot-user']);
  }

  //deviceprofile

  fontDetector() {
    var detector: any = {}, baseFonts, testString, testSize, h, s, defaultWidth = {}, defaultHeight = {}, index;

    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.
    baseFonts = ['monospace', 'sans-serif', 'serif'];

    //we use m or w because these two characters take up the maximum width.
    // And we use a LLi so that the same matching fonts can get separated
    testString = "mmmmmmmmmmlli";

    //we test using 72px font size, we may use any size. I guess larger the better.
    testSize = '72px';

    h = document.getElementsByTagName("body")[0];

    // create a SPAN in the document to get the width of the text we use to test
    s = document.createElement("span");
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    for (index in baseFonts) {
      //get the default width for the three base fonts
      s.style.fontFamily = baseFonts[index];
      h.appendChild(s);
      defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
      defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
      h.removeChild(s);
    }

    detector.detect = function (font) {
      var detected = false, index, matched;
      for (index in baseFonts) {
        s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
        h.appendChild(s);
        matched = (s.offsetWidth !== defaultWidth[baseFonts[index]] || s.offsetHeight !== defaultHeight[baseFonts[index]]);
        h.removeChild(s);
        detected = detected || matched;
      }
      return detected;
    };

    return detector;
  }

  collectScreenInfo = function () {
    var screenInfo: any = {};
    if (screen) {
      if (screen.width) {
        screenInfo.screenWidth = screen.width;
      }

      if (screen.height) {
        screenInfo.screenHeight = screen.height;
      }

      if (screen.pixelDepth) {
        screenInfo.screenColourDepth = screen.pixelDepth;
      }
    } else {
      console.warn("Cannot collect screen information. screen is not defined.");
    }
    return screenInfo;
  }
  collectTimezoneInfo = function () {
    var timezoneInfo: any = {}, offset = new Date().getTimezoneOffset();

    if (offset) {
      timezoneInfo.timezone = offset;
    } else {
      console.warn("Cannot collect timezone information. timezone is not defined.");
    }

    return timezoneInfo;
  }
  collectBrowserPluginsInfo = function () {

    if (navigator && navigator.plugins) {
      var pluginsInfo: any = {}, i, plugins = navigator.plugins;
      pluginsInfo.installedPlugins = "";

      for (i = 0; i < plugins.length; i++) {
        pluginsInfo.installedPlugins = pluginsInfo.installedPlugins + plugins[i].filename + ";";
      }

      return pluginsInfo;
    } else {
      console.warn("Cannot collect browser plugin information. navigator.plugins is not defined.");
      return {};
    }

  }
  // Getting geoloation takes some time and is done asynchronously, hence need a callback which is called once geolocation is retrieved.
  collectGeolocationInfo() {
    var geolocationInfo: any = {},
      successCallback = function (position) {
        geolocationInfo.longitude = position?.coords?.longitude;
        geolocationInfo.latitude = position?.coords?.latitude;
      }, errorCallback = function (error) {
        console.warn("Cannot collect geolocation information. " + error.code + ": " + error.message);
      };
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.warn("Cannot collect geolocation information. navigator.geolocation is not defined.");
    }
  }
  collectBrowserFontsInfo() {
    var fontsInfo: any = {}, i, fontsList = ["cursive", "monospace", "serif", "sans-serif", "fantasy", "default", "Arial", "Arial Black",
      "Arial Narrow", "Arial Rounded MT Bold", "Bookman Old Style", "Bradley Hand ITC", "Century", "Century Gothic",
      "Comic Sans MS", "Courier", "Courier New", "Georgia", "Gentium", "Impact", "King", "Lucida Console", "Lalit",
      "Modena", "Monotype Corsiva", "Papyrus", "Tahoma", "TeX", "Times", "Times New Roman", "Trebuchet MS", "Verdana",
      "Verona"];
    fontsInfo.installedFonts = "";

    for (i = 0; i < fontsList.length; i++) {
      // if (this.fontDetector.detect(fontsList[i])) {
      //     fontsInfo.installedFonts = fontsInfo.installedFonts + fontsList[i] + ";";
      // }
    }
    return fontsInfo;
  }

  async getScrennData() {
    this.devicePrint.screen = this.collectScreenInfo();
    this.devicePrint.timezone = this.collectTimezoneInfo();
    this.devicePrint.plugins = this.collectBrowserPluginsInfo();
    this.devicePrint.fonts = this.collectBrowserFontsInfo();

    if (navigator.userAgent) {
      this.devicePrint.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      this.devicePrint.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      this.devicePrint.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      this.devicePrint.appVersion = navigator.appVersion;
    }
    if (navigator.platform) {
      this.devicePrint.platform = navigator.platform;
    }
    if (navigator.product) {
      this.devicePrint.product = navigator.product;
    }
    if (navigator.productSub) {
      this.devicePrint.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      this.devicePrint.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      this.devicePrint.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      this.devicePrint.language = navigator.language;
    }
    if (navigator.languages) {
      this.devicePrint.userLanguage = navigator.languages;
    }

    const device = new FRDevice()
    //,let loc = await device.getLocationCoordinates()
    let deviceMeta = await device.getDisplayMeta()
    let deviceInfo = {
      identifier: device.getIdentifier(),
      metadata: {
        hardware: {
          cpuClass: null,
          deviceMemory: 8,
          hardwareConcurrency: navigator.hardwareConcurrency,
          maxTouchPoints: navigator.maxTouchPoints,
          oscpu: null,
          display: {
            width: deviceMeta.width,
            height: deviceMeta.height,
            pixelDepth: deviceMeta.pixelDepth,
            angle: deviceMeta.angle
          }
        },
        browser: {
          userAgent: navigator.userAgent,
          appName: navigator.appName,
          appCodeName: navigator.appCodeName,
          appVersion: navigator.appVersion,
          appMinorVersion: null,
          buildID: null,
          product: navigator.product,
          productSub: navigator.productSub,
          vendor: navigator.vendor,
          vendorSub: "",
          browserLanguage: null,
          plugins: this.devicePrint.plugins.installedPlugins
        },
        platform: {
          deviceName: device.getDeviceName(),
          language: navigator.language,
          platform: this.devicePrint.platform,
          userLanguage: null,
          systemLanguage: null,
          fonts: this.devicePrint.fonts.installedFonts,
          timezone: this.devicePrint.timezone.timezone
        }
      }
    }
    this.deviceDetails = deviceInfo
  }


}
